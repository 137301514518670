<template>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
            <slot v-for="(item, index) in list" :$index="index" :item="item" name="item"></slot>
        </van-list>
    </van-pull-refresh>
</template>

<script>
    export default {
        name: "MyScrollList",
        props: {
            option: Object,
            pageSize: {
                type: Number,
                default: 20
            },
        },
        data() {
            return {
                list: [],
                loading: false,
                finished: false,
                refreshing: false,
                pageNum: 0,
            }
        },
        methods: {
            getItem(index) {
                return this.list[index];
            },
            onDelete(index) {
                this.list.splice(index, 1);
            },
            getQuery() {
                let query = JSON.parse(JSON.stringify(this.option.query));
                Object.keys(query).forEach(key => {
                    if (!query[key]) this.$delete(query, key);
                });
                if (this.option.beforeQuery) query = this.option.beforeQuery(query);

                return {
                    ...query,
                    pageSize: this.pageSize,
                    pageNum: this.pageNum
                };
            },
            onLoad() {
                if (this.refreshing) {
                    this.list = [];
                    this.refreshing = false;
                }
                let {api} = this.option;
                if (!api) {
                    this.finished = true;
                    return;
                }
                let query = this.getQuery();
                api(query).then(res => {
                    let {list, pages} = res.data;
                    this.list = [...this.list, ...list];
                    this.loading = false;
                    // 如果总页数 = 当前页数，说明已经加载完了
                    let pageNum = this.pageNum + 1;
                    if (pages === 0 || pages === pageNum) {
                        this.finished = true;
                        // 总页数 > 当前页数，pageNum + 1
                    } else if (pages > this.pageNum) {
                        this.pageNum++;
                    }
                }).catch(() => {
                    this.finished = true;
                });
            },
            onRefresh() {
                // 清空列表数据
                this.finished = false;
                this.pageNum = 0;
                // 重新加载数据
                // 将 loading 设置为 true，表示处于加载状态
                this.loading = true;
                this.onLoad();
            },
            // 点击搜索
            onSearch() {
                this.refreshing = true;
                this.onRefresh();
            },
        }
    }
</script>

<style lang="scss" scoped>
    .MyScrollList {
        height: 100%;
    }
</style>
